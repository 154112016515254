export const VARIABLE_CONFIG = {
    TYPE: {
        LINK: 'link',
        BUTTON: 'button',
        ICON: 'icon',
        TOOLTIP: 'tooltip',
        FIELD: 'field',
        RADIO_BUTTON: 'radio button',
        TILE: 'tile',
        TAB: 'tab',
        CHECKBOX: 'checkbox',
        DROPDOWN: 'dropdown'
    },
    ACTION: {
        NAV: 'nav',
        INPUT: 'input',
        SELECT: 'select',
        SET: 'set',
        VIEW: 'view',
        SEARCH: 'search',
        DROPDOWN: 'dropdown',
        PHONE_CALL: 'phone call',
        LIVE_CHAT: 'live chat'
    },
    STYLE: {
        GRAPHIC: 'graphic',
        CTA_PRIMARY: 'cta-primary',
        CTA_SECONDARY: 'cta-secondary',
        FIELD: 'field',
        SEARCH_BAR: 'search bar',
        AUTOSUGGEST: 'autosuggest',
        LIST: 'list',
        TEXT_BOX: 'text box',
        RESERVATION_FLOW_NAV_BAR: 'reservation flow nav bar'
    },
    PLACEMENT: {
        HEADER: 'header',
        CONTENT: 'content',
        FOOTER: 'footer',
        LOCATIONOVERLAY: 'locationOverlay'
    },
    MODAL: {
        POP_UP: 'pop-up',
        START_DATE: 'start date',
        START_TIME: 'start time',
        END_DATE: 'end date',
        END_TIME: 'end time',
        PICKUP_DATE: 'pickup date',
        RETURN_DATE: 'return date',
        PICKUP_TIME: 'pickup time',
        RETURN_TIME: 'return time',
        NONE: 'none'
    },
    ECOMMERCE_PAGE: {
        HOME: 'home',
        CATEGORY: 'category',
        DETAIL: 'detail',
        CART: 'cart',
        MINICART: 'minicart',
        CHECKOUT: 'checkout',
        CONFIRMATION: 'confirmation',
        OTHER: 'other',
        PLP: 'plp',
        SRP: 'srp',
        CREATE_PROJECT: 'create project',
        PDP: 'pdp',
        HOME_PAGE: 'home page'
    },
    CURRENCY_CODE: {
        USA: 'USD',
        CANADA: 'CAD'
    },
    EVIRONMENT: {
        TEST: 'test',
        LIVE: 'live'
    },
    ACCOUNT_PAYMENT_TYPE: {
        CREDIT: 'credit',
        CASH: 'cash'
    },
    AUTHENTICATION: {
        GUEST: 'guest',
        AUTHENTICATED: 'authenticated'
    },
    BUSINESS_SIZE: {
        SMALL: 'small',
        MIDDLE: 'middle',
        BIG: 'big'
    },
    SUNBELT_LOCATION: {
        STATE: 'state',
        CITY: 'city',
        BRANCH_NUMBER: 'branch number',
        NONE: 'none'
    },
    IS_EMPLOYEE: {
        YES: 'yes',
        NO: 'no'
    },
    NOTIFICATIONS: {
        YES: 'yes',
        NO: 'no'
    },
    JOB_TITLE: {
        MANAGER: 'manager',
        CEO: 'ceo',
        PRESIDENT: 'president'
    },
    RENTAL_METHOD: {
        DELIVERY: 'delivery',
        PICKUP: 'pick_up'
    },
    OPTIONAL_PLANS: {
        RENTAL_PROTECTION_PLAN: 'rental_protection_plan',
        FUEL_CONVENIENCE_PLAN: 'fuel_convenience_plan'
    },
    SELECT_METHOD: {
        SELECTED: 'selected',
        NONE: 'none'
    },
    EMPTY_STRING: {
        PAGE_UNSPECIFIED: '(page:unspecified)',
        PAGE_BLANK: '(page:blank)'
    },
    MISCELLANEOUS: {
        SET_LOCATION_LINK: 'set_location_link',
        LOCATION_FIELD_ERROR: 'location_field_error'
    },
    ADDRESS_ERROR: {
        ADDRESS1: 'address1',
        CITY: 'city',
        STATE: 'state',
        ZIPCODE: 'zipcode'
    },

    ERROR: {
        FORMFIELD: 'form_field',
        error: 'error'
    },
    EVENT: {
        UAEVENT: 'uaevent',
        CLOSED: 'closed',
        COMPLETE: 'complete',
        RESET: 'reset',
        SUBMIT: 'submit',
        START_DATE_CLICK: 'startDateClick',
        END_DATE_CLICK: 'endDateClick',
        FORM_VIEWED: 'form_viewed',
        FORM_COMPLETED: 'form_completed'
    },
    ECOMMERCE: {
        UNDEFINED: 'undefined',
        UNDEFINED_WITHOUT_STRINGIFY: undefined
    },
    EVENT_CATEGORY: {
        CHECKOUT_PAGE: 'checkout page',
        BANNER: 'banner',
        GUIDE_BANNER: 'guide banner',
        MINI_CART: 'mini cart',
        NAVIGATION: 'navigation',
        SEARCH_BAR: 'search bar',
        SEARCH: 'search',
        ADD_ON: 'add_on',
        ACCESSOSIERS: 'accessories',
        FAVOURITE_PRODUCTS: 'favorite products',
        CONTACT_US: 'contact us',
        RECENTLY_VIEWED: 'recently viewed',
        VIEW_ALL_RECENTLY_VIEWED: 'view all recently viewed',
        REQUEST_QUOTE: 'request quote',
        FREQUENTLY_RENTED: 'frequently rented'
    },
    EVENT_ACTION: {
        GUEST_SELECT: 'guest select',
        GRID: 'grid',
        LIST: 'list',
        SELECT: 'select',
        SELECT_HEADER: 'select header',
        SELECT_OPTIONAL_PLANS: 'select optional plans',
        GUEST_REGISTRATION: 'guest registration',
        SELECT_TERMS_AND_CONDITIONS: 'select terms and conditions',
        SELECT_LOCATION: 'select location',
        FILTER_ICON_CLICK: 'filter icon click',
        SEARCH_RELATED_INFO: 'search related info',
        RELATED_INFO: 'related information',
        CANCEL: 'cancel',
        SELECT_STORE: 'select store',
        WARNING: 'warning',
        ADD_TO_FAVOURITES: 'add to favorites',
        IN_STORE_PICKUP_ERROR: 'in-store pickup error',
        CANCEL_CREATE_NEW_PROJECT: 'cancel create new project',
        CREATE_NEW_PROJECT: 'create new project',
        ESTIMATE_DELIVERY: 'estimate delivery',
        SUBMIT: 'submit'
    },
    EVENT_LABEL: {
        CREATE_ACCOUNT: 'create account',
        SIGNIN: 'Sign in',
        CONTINUE_AS_GUEST: 'Continue as guest',
        EDIT_YOUR_DETAILS: 'edit your details',
        CHECKED_SEND_ME_ORDER_DETAILS: 'checked send me order updates via text',
        UNCHECKED_SEND_ME_ORDER_DETAILS: 'unchecked send me order updates via text',
        OPTIONAL_PLANS_EDIT: 'optional plans edit',
        GET_YOUR_ORDER_EDIT: 'get your order edit',
        ORDER_DETAILS_EDIT: 'oder details edit',
        CHECKED_RENTAL_PROTECTION: 'checked rental protection plan',
        UNCHECKED_RENTAL_PROTECTION: 'unchecked rental protection plan',
        LEARN_MORE_RENTAL_PROTECTION: 'learn more rental protection',
        CHECKED_PREPAY_FUEL: 'checked prepay fuel',
        UNCHECKED_PREPAY_FUEL: 'unchecked prepay fuel',
        LEARN_MORE_PREPAY_FUEL: 'learn more prepay fuel',
        ESTIMATE_DELIVERY_FEE: 'Estimate Delivery Fee',
        SAVED_PAYMENT: 'saved payment',
        BACK: 'back',
        CANCEL_ADDING_ACCESSORIES: 'cancel adding accessories',
        RETURN_TO_CHECKOUT: 'return to checkout',
        CHECKED_TERMS_AND_CONDITIONS: 'checked terms and conditions',
        LEARN_MORE_TERMS_AND_CONDITIONS: 'learn more terms and conditions',
        FIRST_PAGE_ICON: 'first page icon',
        LAST_PAGE_ICON: 'last page icon',
        FILTER_ICON: 'filter icon',
        CLOSE_FILTERS: 'close filters',
        BACK_TO_ORDER_DETAILS_PAGE: 'back to order details page',
        GET_DIRECTIONS: 'get directions',
        CHOOSE_STORE: 'choose store',
        BEST_MATCH: 'best match',
        DELIVERY_PROJECT_DETAILS: 'delivery project details',
        GET_ESTIMATE: 'get estimate',
        UPDATE_ADDRESS: 'update address',
        UPDATE_LOCATION: 'update location',
        REMOVE_DELIVERY: 'remove delivery',
        CONTACT_US_FORM_SUBMIT: 'contact us form submit',
        VIEW_ALL_FREQUENTLY_RENTED: 'view all frequently rented',
        VIEW_ALL_FAVORITES: 'view all favorites',
        INSTORE_PICKUP: 'In-store pickup',
        ROUNDTRIP_DELIVERY: 'Round-trip delivery',
        CREATE_A_SUNBELT_ACC: 'Create a Sunbelt account',
        SAVE_AND_CONTINUE: 'Save and continue',
        ARIA_LABEL_SAVE_AND_CONTINUE: 'Click here to Save and continue'
    },
    BANNER_POSITION: {
        CONTENT: 'content',
        HEADER: 'header',
        FOOTER: 'footer'
    },
    PAGE_TYPE: {
        HOME_PAGE: 'home page',
        PRODUCT_PAGE: 'product details page',
        SEARCH_PAGE: 'search results',
        LOCATION_PAGE: 'location page',
        PLP: 'plp',
        ALTERNATE_INVENTORY: 'alternate inventory',
        PDP: 'pdp',
        SEASONAL_RENTALS: 'seasonal rentals',
        P2P_WISHLIST_ITEMS: 'p2p wishlist items',
        EQUIPMENT_AND_TOOLS_PAGE: 'equipment and tools page'
    },
    CTA_TYPE: {
        GRAPHIC: 'graphic',
        LINK: 'link'
    },
    ENVIRONTMENT: {
        TEST: 'test',
        LIVE: 'live'
    },
    REGION: {
        US: 'us',
        CANADA: 'canada'
    },
    VIRTUAL_PAGE_TITLE: {
        CHECKOUT_PICKUP_LOCATION: 'checkout pickup location',
        CHECKOUT_DELIVERY_LOCATION: 'checkout delivery location',
        CHECKOUT_YOUR_DETAILS: 'checkout your details',
        CHECKOUT_ACCESSORIES: 'checkout accessories',
        CHECKOUT_OPTIONAL_PLANS: 'checkout optional plans',
        CHECKOUT_PROJECT_INFO: 'checkout project info',
        CHECKOUT_PAYMENT: 'checkout payment',
        CONFIRMATION: 'confirmation',
        CHECKOUT_ADDITIONAL_INFO: 'checkout additional info',
        CHECKOUT_YOUR_CART: 'checkout your cart',
        CHECKOUT_IDENTIFICATION: 'checkout identification',
        CHECKOUT_IDENTIFICATION_LOGIN: 'checkout identification login',
        CHECKOUT_IDENTIFICATION_ACCOUNT_CREATION: 'checkout identification account creation',
        CHECKOUT_IDENTIFICATION_GUEST: 'checkout identification guest'
    },
    VIRTUAL_PAGE_URL: {
        DETAILS: 'details',
        PICKUP_LOCATION: 'pickup-location',
        DELIVERY: 'delivery',
        ACCESSOSIERS: 'accessories',
        OPTIONAL_PLANS: 'optional-plans',
        CUSTOM_ACCOUNT_PROJECT: 'custom-account-project',
        GUEST_DETAILS: 'guest-details',
        CHECKOUT_PAYMENT: 'payment',
        CUSTOM_ACCOUNT_ADDITIONAL_INFO: 'custom-account-additionalinfo',
        CART: 'cart',
        IDENTIFICATION: 'identification',
        LOGIN: 'login',
        ACCOUNT_CREATION: 'account-creation',
        GUEST: 'guest'
    },
    DOMAIN: {
        PRODUCTION: 'production',
        STAGING: 'stage',
        QA: 'qa'
    },
    EVENT_STRUCTURE: {
        ECOMMERCE: 'ecommerce',
        CLICK: 'click',
        DETAIL: 'detail',
        ADD: 'add',
        REMOVE: 'remove',
        CHECKOUT: 'checkout',
        CHECKOUT_OPTION: 'checkout_option'
    },
    RESERVATION_STATUS: {
        SUCCESS_FULL_FULFILLMENT: 'Success - Full Fulfillment',
        SUCCESS_PARTIAL_FULFILLMENT: 'Success - Partial Fulfillment',
        SUCCESS_NO_FULFILLMENT: 'Success - No Fulfillment + Last Resort'
    },
    RESERVATION_TYPE: {
        PRIMARY: 'primary',
        SECONDARY: 'secondary',
        TERTIARY: 'tertiary'
    },
    TIMER_MODAL_ACTION: {
        RELOGIN: 'RELOGIN',
        RELOGIN_WITH_REDIRECTION: 'RELOGIN_WITH_REDIRECTION',
        LOGOUT_ON_ERROR: 'LOGOUT_ON_ERROR',
        CONTINUE_SESSION: 'CONTINUE_SESSION',
        RESET_USER: 'RESET_USER'
    },
    EVENT_PAGE_TITLE: {
        GUEST_CHECKOUT_AUTHENTICATION_PAGE: 'Checkout - Authentication'
    },
    EVENT_PAGE_LOCATIONS: {
        GUEST_CHECKOUT_AUTHENTICATION_PAGE_LOCATION: '/checkout-page/authentication'
    },
    EVENT_LINK_LOCATION: {
        BODY: 'body'
    },
    EVENT_LINK_DESTINATION: {
        SIGN_IN_PAGE: '/login/',
        CHECKOUT_PAGE: '/checkout-page/',
        CREATE_ACCOUNT: '/createaccount/customer/',
        CHANGE_DATES: '#changedates',
        CHANGE_STORE: '#changestore',
        NOT_AVAILABLE: '#notavailable'
    },
    CHECKOUT_PAGE_TITLE: {
        ORDER_DETAILS: 'Checkout - Order Details',
        YOUR_DETAILS: 'Checkout - Your Details',
        CREATE_JOBSITE: 'Checkout - Create a Jobsite',
        HTGO: 'Checkout - How to get your order',
        ACCESSORIES_AND_ADDONS: 'Checkout - Accessories and add-ons',
        OPTIONAL_PLANS: 'Checkout - Optional plans',
        PROJECT_DETAILS: 'Checkout - Project details',
        CUSTOM_ACCOUNT_INFO: 'Checkout - Custom Account Info',
        PAYMENT: 'Checkout - Payment',
        SUBMIT_RESERVATION: 'Checkout - Submit Reservation',
        TRANSMIT_OR_SAVE: 'Checkout - Transmit'
    },
    CHECKOUT_PAGE_LOCATION: {
        ORDER_DETAILS: '/checkout-page/order-details ',
        YOUR_DETAILS: '/checkout-page/your-details',
        CREATE_JOBSITE: '/checkout-page/create-a-jobsite',
        HTGO: '/checkout-page/how-to-get-your-order',
        ACCESSORIES_AND_ADDONS: '/checkout-page/accessories-and-add-ons',
        OPTIONAL_PLANS: '/checkout-page/optional-plans',
        PROJECT_DETAILS: '/checkout-page/project-details',
        CUSTOM_ACCOUNT_INFO: '/checkout-page/custom-account-info',
        PAYMENT: '/checkout-page/payment',
        SUBMIT_RESERVATION: '/checkout-page/submit-reservation',
        TRANSMIT_OR_SAVE: '/checkout-page/transmit',
        CREATE_JOBSITE: '/checkout-page/create-jobsite'
    },
    CHECKOUT_FIELD_NAMES: {
        ADDRESS: 'address',
        CITY: 'city',
        STATE: 'state',
        ZIP: 'zip',
        YOUR_DETAILS_FORM_FIELDS: 'your details form fields',
        PICK_UP_TIME: 'pick up time',
        RETURN_TIME: 'return time',
        STATE: 'state',
        STORE_SELECTION: 'store selection',
        SOMEONE_ELSE_FIRST_NAME: 'Someone else will pick up - first name',
        SOMEONE_ELSE_LAST_NAME: 'Someone else will pick up - last name',
        SOMEONE_ELSE_PHONE: 'Someone else will pick up - phone',
        SOMEONE_ELSE_EMAIL: 'Someone else will pick up - email',
        DISNEY_FORM_FIELDS: 'Disney custom acc form field',
        DUKE_FORM_FIELDS: 'Duke custom acc form field',
        ACCESS_NOTES: 'Access notes',
        PO_NUMBER: 'PO Number',
        PRIMARY_CONTACT_NAME: 'Primary contact name',
        PHONE_NUMBER: 'Phone number',
        DELIVERY_TIME: 'Delivery time',
        START_DATE: 'start date',
        END_DATE: 'end date'
    },
    CHECKOUT_ERROR_MESSAGES: {
        EMPTY_PICKUP_TIME: 'Please enter a Pickup time',
        EMPTY_DELIVERY_TIME: 'Please enter a Delivery time'
    },
    CHECKOUT_YOUR_DETAILS_FIELDS: {
        FIRST_NAME: 'your details - first name',
        LAST_NAME: 'your details - last name',
        EMAIL: 'your details - email',
        PHONE: 'your details - phone'
    },
    MKTO_CONTACT_US: {
        POSTAL_CODE: 'PostalCode',
        ADDRESS: 'Address',
        CITY: 'City',
        STATE: 'State'
    },
    MKTO_REQUEST_QUOTE: {
        POSTAL_CODE: 'jobSiteZipCode',
        ADDRESS: 'jobSiteAddress',
        CITY: 'jobSiteCity',
        STATE: 'jobSiteState'
    },
    ALTERNATE_INVENTORY: {
        SIMILAR: 'similar',
        SIMILAR_PRODUCTS: 'Similar Products',
        SIMILAR_EQUIPMENT: 'Similar equipment',
        TEXT: 'text',
        BODY: 'body'
    },
    FORM_ID: {
        REQUEST_QUOTE: '3022',
        CONTACT_US: '3044'
    },
    FORM_SUBMIT_SUBJECT: {
        RENT_EQUIPMENT: 'Rent Equipment'
    },
    CAP_VIEW_MODE: {
        DATE_VIEW: 'Date',
        LOCATION_VIEW: 'Location',
        EDIT_BUTTON_FOCUS: 'editFocus'
    },
    P2P: {
        LOGOUT: 'LOGOUT',
        ITEM_FAVORITES: 'favorites',
        ITEM_FAVORITES_LIST: 'Favorite List'
    },
    PRODUCT: {
        LIST_ID: 'pdp',
        LIST_NAME: 'Product Detail'
    },
    PRODUCT_LISTING: {
        RECENT: 'recent',
        RECENT_ORDERS: 'Recent Orders'
    },
    CART: {
        LIST_ID: 'cart',
        LIST_NAME: 'Cart Page'
    },
    TILE_STATUS: {
        REQUEST_A_QUOTE: 'request a quote',
        CHANGE_STORE_AND_DATE: 'change store and date',
        CHANGE_STORE_OR_DATE: 'change store or date',
        NO_LOCATION: 'no location',
        NO_DATES: 'no dates',
        NO_LOCATION_AND_DATES: 'no location and dates',
        CHANGE_DATE: 'change date',
        UNAVAILABLE: 'unavailable',
        RENT: 'rent',
        CHANGE_STORE: 'change store',
        DEFAULT: ''
    },
    STARTING_IN_STATUS: {
        GRT72HRS: '72+',
        GRT24HRS: '24-72',
        LESS24HRS: '<24'
    },
    AVAILABILITY_TOGGLE: {
        SHOW: 'show',
        HIDE: 'hide'
    },
    PRODUCT_CARD: {
        PLP_LIST_NAME: 'Product List Page',
        SRP_LIST_NAME: 'Search Results Page',
        POPULAR_LIST_ID: 'popular',
        POPULAR_LIST_NAME: 'Popular rentals',
        SEASONAL_LIST_ID: 'seasonal',
        SEASONAL_LIST_NAME: 'Seasonal offerings'
    },
    CART_ITEM_STATUS_CHANGED_REASON: {
        RENTAL_DETAILS_CHANGED: 'rental details changed',
        PLACE_INSTORE_PICKUP: 'place instore pickup order',
        STORE_DETAILS_CHANGED: 'store changed from product tile',
        DATES_DETAILS_CHANGED: 'dates changed from product tile',
        ACCOUNT_DETAILS_CHANGED: 'user switched account',
        CHANGED_FROM_CHRONOS_STORE: 'availability changed from chronos store',
        STORE_CHANGED_FROM_DISCREPENCY_DRAWER: 'store changed from discrepency drawer',
        NO_CHRONOS_STORE_AVAILABLE: 'no chronos store available',
        ATP_SOURCES_DISCREPENCY: 'available from sources but unavailable from atp'
    }
};
